<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="5">
          <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
            Invalid credentials.
          </CAlert>
          <CCardGroup>
            <CCard class="p-3">
              <CCardBody>
                <CForm @submit.prevent="onSubmit">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-user"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.email"
                          type="email"
                          class="form-control"
                          autocomplete="email"
                          v-model="login.email"
                          placeholder="Email"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="$v.login.$error && !$v.login.email.required"
                    >Email is required</small
                    >
                    <small
                        class="error"
                        v-if="$v.login.$error && !$v.login.email.email"
                    >Email is not valid</small
                    >
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                        ><CIcon name="cil-lock-locked"
                        /></span>
                      </div>
                      <input
                          :maxlength="maxlength.password"
                          type="password"
                          class="form-control"
                          autocomplete="password"
                          v-model="login.password"
                          placeholder="Password"
                      />
                    </div>
                    <small
                        class="error"
                        v-if="$v.login.$error && !$v.login.password.required"
                    >Password is required</small
                    >
                  </div>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                      >Login
                      </CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton
                          color="link"
                          class="px-0"
                          v-on:click="forgotUrl()"
                      >Forgot password?
                      </CButton>
                      <CButton color="link" class="d-lg-none"
                      >Register now!
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import Vue from "vue";
import {General} from "/src/store/url.js";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {required, email} from "vuelidate/lib/validators";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted"; // https://github.com/shakee93/vue-toasted
import {Maxlength} from "/src/store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};

Vue.use(VueNotifications, options);
// Register it globally

export default {
  name: "SignIn",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      dismissCountDown: 0,
      login: {
        email: "",
        password: "",
      },
      maxlength: {
        email: Maxlength.login.email,
        password: Maxlength.login.password,
      },
      module: General,
    };
  },
  validations: {
    login: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  notifications: {
    showLoginError: {
      // You can have any name you want instead of 'showLoginError'
      title: "Login Failed",
      message: "Failed to authenticate",
      type: "error", // You also can use 'VueNotifications.types.error' instead of 'error'
    },
  },
  mounted() {
    // localStorage.setItem('isLogged',false);
    // // store.commit("LOGIN_USER");

    store.commit("showLoader", false); // Loader Off
    this.dismissCountDown = 0;
  },
  methods: {
    forgotUrl() {
      let self = this;
      self.$router.push({name: "Forgotpassword"});
    },
    onSubmit() {
      this.$v.login.$touch();
      if (this.$v.login.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        axios
            .post(this.loginUrlApi(this.module), self.login)
            .then(function (response) {
              // alert('Login Response');
              if (response.status === 200) {
                let responseData = response.data.data;
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + responseData.token;
                localStorage.setItem("token", responseData.token);
                localStorage.setItem("user_name", responseData.firstName); //+ " " + response.data.lastName
                localStorage.setItem("email", responseData.email);
                localStorage.setItem("permissions", responseData.permissions);
                localStorage.setItem("user_id", responseData.id);
                localStorage.setItem("avatar", responseData.avatar);
                localStorage.setItem("userType", responseData.userType);
                localStorage.setItem("roleName", responseData.roleName);
                localStorage.setItem(
                    "userDefaultImage",
                    responseData.userDefaultImage
                );
                localStorage.setItem(
                    "defaultSignImage",
                    responseData.defaultImage
                );
                localStorage.setItem(
                    "defaultDocImage",
                    responseData.defaultDocImage
                );
                localStorage.setItem(
                    "defaultPdfImage",
                    responseData.defaultPdfImage
                );
                localStorage.setItem(
                    "defaultXlsImage",
                    responseData.defaultXlsImage
                );
                localStorage.setItem("preloading", responseData.preloader);
                self.$store.commit("LOGIN_USER");
                self.loader = true;
                localStorage.setItem("isLogged", true);
                if (
                    localStorage.getItem("redirectName") != null &&
                    localStorage.getItem("redirectNameId") != null
                ) {
                  let props = self.$router.resolve({
                    name: localStorage.getItem("redirectName"),
                    params: {id: localStorage.getItem("redirectNameId")},
                  });
                  localStorage.removeItem("redirectName");
                  localStorage.removeItem("redirectNameId");
                  self.$router.push(props.location.path);
                } else {
                  self.$router.push("/dashboard");
                }
              }
            })
            .catch(function () {
              localStorage.setItem("token", "");
              self.dismissCountDown = 10;
              self.showLoginError();
            });
      }
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
  },
};
</script>
